import { useEffect, useState } from 'react';

import { AuthBtn } from 'components/auth/auth-btn';
import { useAccount, useStore, useToast } from 'contexts';

export const FollowBtn = ({ type = 'card', model, rootApi }) => {
	const [follow, setFollow] = useState<any>({});
	const [taskRunning, setTaskRunning] = useState(false);
	const account = useAccount();
	const store = useStore();
	const toast = useToast();

	// Hooks
	useEffect(() => {
		setFollow(model?.social?.follower || {});
	}, [model?.social]);

	// Methods
	const onClick = async () => {
		try {
			setTaskRunning(true);
			const response = await store.queryRecord({ url: `${rootApi}/follow` });
			setFollow(response.data || {});
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning(false);
		}
	};

	const props = { follow, taskRunning, onClick };

	switch (type) {
		case 'card':
			return <FollowCardBtn {...props} />;
		case 'hero':
			return <FollowHeroBtn {...props} />;
		case 'profile':
			return <FollowProfileBtn {...props} />;
	}
};

const FollowCardBtn = ({ follow, taskRunning, onClick }) => (
	<AuthBtn
		type='border'
		size='icon'
		active={follow.id}
		icon={follow.id ? 'check' : 'plus'}
		taskRunning={taskRunning}
		onClick={onClick}
	/>
);

const FollowHeroBtn = ({ follow, taskRunning, onClick }) => (
	<AuthBtn
		type='border'
		prevIcon={follow.id ? 'check' : 'plus'}
		active={follow.id}
		taskRunning={taskRunning}
		onClick={onClick}>
		{follow.id ? 'Following' : 'Follow'}
	</AuthBtn>
);

const FollowProfileBtn = ({ follow, taskRunning, onClick }) => (
	<AuthBtn
		type='border'
		active={follow.id}
		prevIcon={follow.id ? 'check' : 'plus'}
		taskRunning={taskRunning}
		onClick={onClick}
		className='grow'>
		{follow.id ? 'Following' : 'Follow'}
	</AuthBtn>
);
