import { useRouter } from 'next/router';

import { BorderLink } from '@playbooks/interface/links';
import { TopicCardActions } from 'components/topic/topic-card-actions';
import {
	DetailCard,
	DisplayCard,
	ListCard,
	PhotoCard,
	PillCard,
	PreviewCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';

export const TopicDetailCard = ({ topic, loading, children, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='hashtag'
			photo={topic.thumbnail}
			status={topic.status}
			title={topic.name}
			subtitle={topic.tagline}
			text={topic.description}
			footer={topic.updatedAt}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</DetailCard>
	);
};

export const TopicDisplayCard = ({ topic, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${topic.meta?.totalBounties} bounties`
			: `${topic.meta?.totalRepos} repositories`;
	// Render
	return (
		<DisplayCard
			icon='hashtag'
			photo={topic.thumbnail}
			title={topic.name}
			subtitle={computedSubtitle}
			text={topic.tagline}
			href={`${rootLink}/topics/${topic.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</DisplayCard>
	);
};

export const TopicListCard = ({ topic, loading, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='hashtag'
			photo={topic.thumbnail}
			title={topic.name}
			subtitle={topic.tagline}
			text={topic.description}
			href={rootLink + `/topics/${topic.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			<TopicCardActions type='grid' topic={topic} rootLink={rootLink} />
		</ListCard>
	);
};

export const TopicPhotoCard = ({ topic, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${topic.meta?.totalBounties} bounties`
			: `${topic.meta?.totalRepos} repositories`;
	// Render
	return (
		<PhotoCard
			icon='hashtag'
			photo={topic.thumbnail}
			title={topic.name}
			subtitle={computedSubtitle}
			href={`${rootLink}/topics/${topic.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PhotoCard>
	);
};

export const TopicPillCard = ({ topic, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${topic.meta?.totalBounties} bounties`
			: `${topic.meta?.totalRepos} repositories`;
	// Render
	return (
		<PillCard
			icon='hashtag'
			photo={topic.thumbnail}
			title={topic.name}
			subtitle={computedSubtitle}
			href={`${rootLink}/topics/${topic.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PillCard>
	);
};

export const TopicPreviewCard = ({ topic, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='hashtag'
			photo={topic.thumbnail}
			title={topic.name}
			subtitle={topic.tagline}
			loading={loading}
			tailwind={tailwind}>
			<BorderLink size='icon' icon='chevron-right' href={rootLink + `/topics/${topic.uuid}`} />
		</PreviewCard>
	);
};

export const TopicSearchCard = ({ topic, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='hashtag'
			modelName='topic'
			photo={topic.thumbnail}
			title={topic.name}
			text={topic.description}
			onClick={() => onClick(topic)}
			tailwind={tailwind}
		/>
	);
};

export const TopicSelectCard = ({ topic, selected = [], onClick, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='hashtag'
			photo={topic.thumbnail}
			title={topic.name}
			selected={selected.map(v => v.id).includes(topic.id)}
			onClick={() => onClick(topic)}
			tailwind={tailwind}
		/>
	);
};
